@import "../node_modules/bootstrap/scss/functions";

$primary: #0c1658;
$secondary: #008a34;
$body-secondary-bg: #e8f5e1;
$navbar-text: #35438d;
$body-bg: #e8f5e1;

@import "../node_modules/bootstrap/scss/bootstrap";
@import "../node_modules/bootstrap-icons/font/bootstrap-icons.min.css";


h2 {
  font-size: calc(1rem + 0.9vw);

  @include media-breakpoint-up(sm) {
    font-size: calc(1.125rem + 0.9vw);
  }

  @include media-breakpoint-up(md) {
    font-size: calc(1.225rem + 0.9vw);
  }

  @include media-breakpoint-up(lg) {
    font-size: calc(1.325rem + 0.9vw);
  }
}

body {
  font-size: 0.85rem;

  @include media-breakpoint-up(sm) {
    font-size: 1.05rem;
  }

  @include media-breakpoint-up(md) {
    font-size: 1.2rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: 1.4rem;
  }
}

p {
  text-align: justify;
}

.logo {
  max-width: 350px;
  max-height: 337px;
  @include media-breakpoint-down(sm) {
    max-width: 280px;
    max-height: 270px;
  }
}

.logo-nav {
  max-width: 65px;

  @include media-breakpoint-down(sm) {
    max-width: 45px;
  }
}

.teacher-image {
  max-width: 220px;

  @include media-breakpoint-down(sm) {
    max-width: 170px;
  }
}

.navbar .navbar-nav .nav-link {
  color: $navbar-text;
}

.navbar .navbar-nav .nav-link:hover {
  color: $primary;
}

.navbar-default {
  background-color: $body-secondary-bg !important;
}
